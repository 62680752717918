import React, { useState, useCallback } from 'react'
import Lightbox from 'react-image-lightbox'
import Gallery from './Gallery'
import 'react-image-lightbox/style.css'

interface Mosaique {
  photos: string[]
}

const Mosaique = ({ photos }: Mosaique): JSX.Element => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  function removeHttp(url: string) {
    return url.replace(/^https?:/, '')
  }

  const openLightbox = useCallback(
    (imgSrc) => {
      const index = photos.findIndex((photo) => photo === removeHttp(imgSrc))
      setCurrentImage(index)
      setViewerIsOpen(true)
    },
    [photos],
  )

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <>
      <Gallery photos={photos} onClick={openLightbox} />
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage]}
          nextSrc={photos[(currentImage + 1) % photos.length]}
          prevSrc={photos[(currentImage + photos.length - 1) % photos.length]}
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() => setCurrentImage((currentImage + photos.length - 1) % photos.length)}
          onMoveNextRequest={() => setCurrentImage((currentImage + 1) % photos.length)}
        />
      )}
    </>
  )
}

export default Mosaique
