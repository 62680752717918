import React from 'react'
import 'assets/scss/flexbin.scss'

interface Gallery {
  photos: string[]
  onClick: (src: string) => void
}

const Gallery = ({ photos, onClick }: Gallery): JSX.Element => {
  return (
    <div className="flexbin">
      {photos.map((photo) => (
        // eslint-disable-next-line
        <div key={"photo-"+photo} onClick={(e) => onClick((e.target as HTMLImageElement).src)} style={{ cursor: 'pointer' }}>
          <img className="lazyload" key={photo} alt="" data-src={photo} />
        </div>
      ))}
    </div>
  )
}

export default Gallery
