import React from 'react'
import { graphql } from 'gatsby'
import EventsPage from 'components/EventsPage'
import Header from 'components/Header'
import Footer from 'components/Footer'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import { ContentfulEvent, ContentfulEventList, Location } from 'shared/types'
import { genericTitle, genericdescription, baseUrl, homeImage } from 'shared/constants'
import { EVENTS_PAGE } from 'shared/routes'
import { mapToEvents, mapToGeneralPageFields } from 'services'

interface Events {
  location: Location
  data: ContentfulEventList
}

const Events = ({ location, data }: Events): JSX.Element => {
  const content = mapToGeneralPageFields(data.contentfulGeneralPageData)
  const eventList = data.contentfulEventOrder.eventOrder.map((node: ContentfulEvent) => mapToEvents(node))
  return (
    <>
      <PageHelmet
        title={genericTitle}
        description={genericdescription}
        url={`${baseUrl}${EVENTS_PAGE}`}
        image={homeImage}
      />
      <ScrollToTop location={location} />
      <Header location={location} contributionUrl={content.contributionUrl} merchandiseUrl={content.merchandiseUrl} />
      <EventsPage eventList={eventList} />
      <Footer />
    </>
  )
}

export const query = graphql`
  query EventList($locale: String) {
    contentfulEventOrder(node_locale: { eq: $locale }) {
      eventOrder {
        eventTitle
        contentful_id
        eventAlbum {
          albumTitle
          albumSubtitle
          albumPictures {
            fixed(width: 800) {
              src
            }
          }
          contentful_id
        }
        eventIcon {
          fixed(width: 500, height: 500, resizingBehavior: SCALE) {
            tracedSVG
          }
        }
      }
    }
    contentfulGeneralPageData {
      contentful_id
      contributionUrl
      merchandiseUrl
    }
  }
`

export default Events
