import React, { ReactChild } from 'react'
import { NavItem, NavLink, Row, Col } from 'reactstrap'

interface Category {
  onClick: VoidFunction
  title: string
  children?: ReactChild
  className?: string
}

const Category = ({ title, children, onClick, className }: Category): JSX.Element => {
  return (
    <Col xs="auto">
      <NavItem onClick={onClick} className={className}>
        <NavLink tag="h3">
          <Row>
            <Col xs="auto" className="category">
              {children}
              <span className="category-title">{title}</span>
            </Col>
          </Row>
        </NavLink>
      </NavItem>
    </Col>
  )
}

export default Category
