import { FormattedMessage } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import { Col, Container, Nav, Row } from 'reactstrap'
import { EventAlbumContent, EventPageContent } from 'shared/types'
import Category from './Category'
import Album from './Album'

interface EventsPage {
  eventList: EventPageContent[]
}

const EventsPage = ({ eventList }: EventsPage): JSX.Element => {
  const [categoryId, setCategoryId] = useState(eventList[0]?.id ? eventList[0].id : '')

  const getAlbums = (cat: string) => {
    const event = eventList.find((e) => e.id === cat)

    if (event) {
      if (event.eventAlbum) {
        return event.eventAlbum
      }
      return []
    }

    return []
  }

  return (
    <div className="main">
      <div className="section text-section top-of-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="title text-center no-margin-bot">
                <FormattedMessage id="event_title" />
              </h2>
            </Col>
          </Row>

          <Row>
            <Nav className="album-nav">
              {eventList.map((event) => (
                <Category
                  key={event.id}
                  className={`${categoryId === event.id && 'is-active'}`}
                  title={event.eventTitle}
                  onClick={() => setCategoryId(event.id)}
                >
                  <img src={event.eventIcon} className="icon" alt="" />
                </Category>
              ))}
            </Nav>
          </Row>
        </Container>
        <Container>
          {getAlbums(categoryId).map((album: EventAlbumContent) => (
            <Album
              key={album.id}
              title={album.albumTitle}
              subTitle={album.albumSubtitle}
              photos={album.albumPictures}
            />
          ))}
        </Container>
      </div>
    </div>
  )
}

export default EventsPage
