import React from 'react'
import { Col, Row } from 'reactstrap'
import Mosaique from './Mosaique'

interface Album {
  title: string
  subTitle: string | null
  photos: string[]
}

const Album = ({ photos, title, subTitle }: Album): JSX.Element => {
  return (
    <>
      <Row className="events">
        <Col className="mr-auto" md="10">
          <h6 className="title events">{title}</h6>
          <h6 className="subTitle events">{subTitle}</h6>
        </Col>
      </Row>
      {photos && <Mosaique photos={photos} />}
    </>
  )
}

export default Album
